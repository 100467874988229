<template>
  <div>
    <div class="row text-center">
      <div class="col">
        <p>Plan seleccionado: {{v_selectedPlan == 0? "Óptimo" : v_selectedPlan + " de " + parseInt(v_fullDataSet.length-1) }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <button type="button" class="btn btn-primary" @click="selectedPlan--" :disabled="v_selectedPlan == 0? true:false">
        <span class="fas fa-long-arrow-alt-left"></span>
      </button>
      <input    v-model="selectedPlan"
                class="form-range mx-3"
                type="range"
                min="0"
                :max="this.v_fullDataSet.length-1"
                />

      <button type="button" class="btn btn-primary" @click="selectedPlan++" :disabled="v_selectedPlan == this.v_fullDataSet.length-1? true:false">
        <span class="fas fa-long-arrow-alt-right"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BlockFull from "@/components/commons/BlockFull";

export default {
  data() {
    return {
      volumenesFinales: null,
      selectedPlan:null
    };
  },
  components: {
    BlockFull,
  },
  computed: {
    ...mapState("planner", ["v_dataSet", "v_fullDataSet", "v_selectedPlan", "v_dataSetEdited"]),
  },
  methods: {
      ...mapActions("planner", [
      "v_updSelectedPlan", 
      "v_updDataSet",
      "v_updDataSetEdited"
    ]),
    updateSelectedYear(){
      this.v_updSelectedYear(this.selectedYear);
    }
  },
  async mounted() {
      if(this.v_selectedPlan != null){
          this.selectedPlan = this.v_selectedPlan;
      }
      if(this.v_dataSet != null){
        this.v_updDataSetEdited(this.v_dataSet);
      }

  },
  watch: {
      selectedPlan: function (newVal, oldVal) {
        if (newVal != null) {
            this.v_updSelectedPlan(newVal);
            this.v_updDataSet(this.v_fullDataSet[newVal]);
            this.v_updDataSetEdited(this.v_dataSet);
        }
    }

  },
};
</script>