<template>
  <div class="w-100 maxWrapperWidth m-4">
    <!-- Selector wizard compatible con rutas de URL-->
    <StepSelector ref="stepSelector" class="mt-2 mb-2" :step="2" />

    <!-- Metemos el contenido en un BlockFull, que le dará un aspecto de sección -->
    <BlockFull titulo="Seleccione un plan" :titleCol="12">
      <template v-slot:content>
        <PlanSelector />
      </template>
    </BlockFull>

    <BlockFull
      class="mt-3"
      :titulo="
        'Situación de los embalses a día 01 de Enero ' + parseInt(v_selectedYear + 1)
      "
      :titleCol="12"
    >
      <template v-slot:content>
        <Vasos :volumenesFinales="volumenesFinales" class="vasitosAgua" />
      </template>
    </BlockFull>

    <!-- Graficas reactivas -->
    <div class="row mt-3">
      <div class="col-12 col-md me-md-1">
        <BlockFull titulo="Aducción" :titleCol="12">
          <template v-slot:content>
            <AreaChart
              ref="planAduccionChart"
              :dataSet="v_dataSet"
              chartID="chartplanAduccion"
              selDataSet="planAduccion"
              yAxisUnit="m3/s"
              estilo="width: 100%; height: 320px;"
              :yAxisLabel="false"
              :basicMode="true"
            />
          </template>
        </BlockFull>
      </div>
      <div class="col-12 col-md mt-3 mt-md-0 ms-md-1">
        <BlockFull titulo="Volúmenes" :titleCol="12">
          <template v-slot:content>
            <AreaChart
              ref="volEmbalsadoChart"
              :dataSet="v_dataSet"
              chartID="chartplanVolumen"
              selDataSet="planVolumen"
              yAxisUnit="hm3"
              estilo="width: 100%; height: 320px;"
              :yAxisLabel="false"
              :basicMode="true"
            />
          </template>
        </BlockFull>
      </div>
    </div>

    <!-- INIT Selector avance -->
    <div class="mt-3">
      <StepActions :step="2" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StepSelector from "./../../common/Steps-selector.vue";
import StepActions from "./../../common/Steps-actions.vue";
import BlockFull from "@/components/commons/BlockFull";
import Vasos from "./components/Vasos.vue";
import PlanSelector from "./components/PlanSelector.vue";
import AreaChart from "./../../common/AreaChart";

export default {
  data() {
    return {
      volumenesFinales: null,
    };
  },
  components: {
    BlockFull,
    StepSelector,
    StepActions,
    Vasos,
    PlanSelector,
    AreaChart,
  },
  computed: {
    ...mapState("planner", [
      "v_dataSet",
      "v_fullDataSet",
      "v_selectedPlan",
      "v_selectedYear",
    ]),
  },
  methods: {},
  async mounted() {
    if (this.v_dataSet && this.v_dataSet.planVolumen) {
      //console.log(this.v_dataSet.planVolumen) //to debug
      this.volumenesFinales = this.v_dataSet.planVolumen.filas[24].masasAgua;
    }
  },
  watch: {
    v_dataSet: function (newVal, oldVal) {
      if (newVal != null) {
        this.volumenesFinales = this.v_dataSet.planVolumen.filas[24].masasAgua;
        return newVal;
      }
    },
  },
};
</script>

<style scoped>
.vasitosAgua{
  zoom:1
}

@media (max-width: 476px) {
.vasitosAgua{
  zoom:0.7
}
}

</style>